<template>
    <MarketComponent />
</template>

<script>
import MarketComponent from "@/components/MarketComponent/MarketComponent";
export default {
  name: "MainMarket",
  components: {
    MarketComponent
  }
};
</script>

<style>
html {
  overflow: auto !important;
}
</style>
