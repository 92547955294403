import api from "@/plugins/api";
import {
    marketPropertyItemUrl,
    marketCreateMenuUrl,
    marketMenuListUrl,
    marketMenuListAutocompleteUrl,
    marketMenuItemUrl
} from "@/store/urls";

/* eslint-disable */
function list({ commit }, data) {
  return new Promise((resolve, reject) => {
      api
          .get(marketMenuListUrl, { params: data })
          .then(response => {
              // eslint-disable-next-line no-console
              console.log(response.data)
              if (response.status === 200) {
                  commit("setMenu", response.data);
                  resolve(response);
              } else {
                  reject(response.data.message);
              }
          })
          .catch(error => {
              reject(error);
          });
  });
}

function listAutocomplete({ commit }, data) {
  return new Promise((resolve, reject) => {
      api
          .get(marketMenuListAutocompleteUrl, { params: data })
          .then(response => {
              if (response.status === 200) {
                  commit("setMenuAutocomplete", response.data);
                  resolve(response);
              } else {
                  reject(response.data.message);
              }
          })
          .catch(error => {
              reject(error);
          });
  });
}

function loadItem({ commit }, data) {
    return new Promise((resolve, reject) => {
        api
            .get(marketPropertyItemUrl+data.id)
            .then(response => {
                if (response.status === 200) {
                    commit("setSelectedItem", response.data);
                    resolve(response);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function newProperty({ commit }, data)
{
    commit("addSelectedItem", {id: null, alias: null, name: null});
}

function newMenuItem({ commit }, data)
{
    commit("addMenuItem", {
        parent: data,
        new: {
            id: null,
            isActive: true,
            name: null,
            ord: null,
            level: data.level+1,
            children: [],
            properties: []
        }
    });
}

function createItemMenu({ commit }, data)
{
    return new Promise((resolve, reject) => {
        api
            .post(marketCreateMenuUrl, data)
            .then(response => {
                if (response.status === 200) {
                    // commit("setSelectedItem", response.data);
                    commit("addNewItem", response.data);
                    resolve(response);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function deleteMenuItem({ commit }, data)
{
    return new Promise((resolve, reject) => {
        api
            .delete(marketMenuItemUrl + data.id, data)
            .then(response => {
                if (response.status === 200) {
                    // commit("setSelectedItem", response.data);
                    commit("removeItem", data);
                    resolve(response);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function saveItemMenu({ commit }, data)
{
    return new Promise((resolve, reject) => {
        api
            .put(marketMenuItemUrl + data.id, data)
            .then(response => {
                if (response.status === 200) {
                    // commit("setSelectedItem", response.data);
                    resolve(response);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export { list, loadItem, newProperty, newMenuItem, createItemMenu, saveItemMenu, deleteMenuItem, listAutocomplete };
