function setMenu(state, data) {
  state.menu = data.result || [];
}

function addNewItem(state, data) {
  let newItem = data.result

  if (newItem.parent === null || (newItem.parent.id === null || newItem.parent.id === 0)) {
    state.menu.push(newItem)
  } else {
    state.menu.forEach((item1) => {
      if (item1.id === newItem.parent.id) {
        item1.children.push(newItem)
      } else {
        item1.children.forEach((item2) => {
          if (item2.id === newItem.parent.id) {
            item2.children.push(newItem)
          }
        })
      }
    });
  }
}

function removeItem(state, data) {
  let deletedId = data.id

  state.menu.forEach((item1, index1) => {
    if (item1.id === deletedId) {
      state.menu.splice(index1, 1)
    } else {
      item1.children.forEach((item2, index2) => {
        if (item2.id === deletedId) {
          state.menu[index1].children.splice(index2, 1)
        } else {
          item2.children.forEach((item3, index3) => {
            if (item3.id === deletedId) {
              state.menu[index1].children[index2].children.splice(index3, 1)
            }
          })
        }
      })
    }
  });
}

function setSelectedItem(state, data) {
  state.selectedItem = data.result || {};
}

function setMenuAutocomplete(state, data) {
  state.menuAutocomplete = data.result || [];
}

function addMenuItem(state, data)
{
  if (data.parent.level === 0) {
    state.menu.push(data.new)
  } else if (data.parent.level === 1) {
    state.menu.forEach(function (item, index){
      if (item.id === data.parent.id) {
        state.menu[index].children.push(data.new)
      }
    })
  }

  // eslint-disable-next-line no-console
  console.log(state.menu, data)
  // state.menu.push(data)
}

export { setMenu, setSelectedItem, addMenuItem, addNewItem, removeItem, setMenuAutocomplete };
