<template>
  <div class="main">
    <MainComponent />
  </div>
</template>

<script>
import MainComponent from "@/components/MainComponent/MainComponent";
export default {
  name: "Main",
  components: {
    MainComponent
  }
};
</script>

<style scoped lang="scss"></style>
