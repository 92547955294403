<template>
  <div class="mailing">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "MainComponentMailing"
};
</script>

<style scoped lang="scss"></style>
