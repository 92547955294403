const serverUrl =
  process.env.NODE_ENV === "development"
    ? window.matronaApiURL
    : window.matronaApiURL;
const loginUrl = `${serverUrl}/web-panel-login`;
const createNewsUrl = `${serverUrl}/article/new`;
const getArticlesUrl = `${serverUrl}/article/full-list`;
const editArticlesUrl = `${serverUrl}/article/`;
const deleteArticlesUrl = `${serverUrl}/article/`;

const createFaqsUrl = `${serverUrl}/faq/new`;
const getFaqsUrl = `${serverUrl}/faq/full-list`;
const editFaqsUrl = `${serverUrl}/faq/`;
const deleteFaqsUrl = `${serverUrl}/faq/`;

const createStoreUrl = `${serverUrl}/store/new`;
const getStoresUrl = `${serverUrl}/store/full-list`;
const editStoreUrl = `${serverUrl}/store/`;
const deleteStoreUrl = `${serverUrl}/store/`;

const createRubricUrl = `${serverUrl}/rubric/new`;
const getRubricsUrl = `${serverUrl}/rubric/full-list`;
const editRubricUrl = `${serverUrl}/rubric/`;
const deleteRubricUrl = `${serverUrl}/rubric/`;
const getPricesUrl = `${serverUrl}/rubric/`;

const createPriceUrl = `${serverUrl}/spec-price/new`;
const editPriceUrl = `${serverUrl}/spec-price/`;
const deletePriceUrl = `${serverUrl}/spec-price/`;

const createCategoryStockUrl = `${serverUrl}/category-stock/new`;
const editCategoryStockUrl = `${serverUrl}/category-stock/`;
const deleteCategoryStockUrl = `${serverUrl}/category-stock/`;
const allCategoryStockUrl = `${serverUrl}/category-stock/all`;

const createMechanicUrl = `${serverUrl}/stock-schema/new`;
const getMechanicsUrl = `${serverUrl}/stock-schema/full-list`;
const editMechanicUrl = `${serverUrl}/stock-schema/`;
const deleteMechanicUrl = `${serverUrl}/stock-schema/`;

const createPromosUrl = `${serverUrl}/stock/new`;
const getPromosUrl = `${serverUrl}/stock-schema/`;
const editPromosUrl = `${serverUrl}/stock/`;
const deletePromosUrl = `${serverUrl}/stock/`;

const getSettingsUrl = `${serverUrl}/settings`;
const editSettingsUrl = `${serverUrl}/settings/edit`;

const getUserUrl = `${serverUrl}/user/current`;
const editUserUrl = `${serverUrl}/user/`;

const smsUrl = `${serverUrl}/notifications/push-send`;

const marketPropsListUrl = `${serverUrl}/properties/list`;
const marketPropsAutocompleteUrl = `${serverUrl}/properties/autocomplete`;
const marketPropertyItemUrl = `${serverUrl}/properties/`;
const marketCreatePropertyUrl = `${serverUrl}/properties`;

const marketMenuListUrl = `${serverUrl}/menu/list`;
const marketMenuItemUrl = `${serverUrl}/menu/`;
const marketCreateMenuUrl = `${serverUrl}/menu`;
const marketMenuListAutocompleteUrl = `${serverUrl}/menu/list/autocomplete`;

const marketMenuSharesCreateUrl = `${serverUrl}/shares`;
const marketSharesListUrl = `${serverUrl}/shares/list`;
const marketShareEditUrl = `${serverUrl}/shares/editItem`;
const marketSharesDelete = `${serverUrl}/shares/delete`;

const marketProductsListUrl = `${serverUrl}/products/list`;
const marketProductSave = `${serverUrl}/products/save`;
const marketProductsDelete = `${serverUrl}/products/delete`;

const marketProductGroupSave = `${serverUrl}/product-group`;
const marketProductGroupList = `${serverUrl}/product-group/list`;
const marketProductsGroupDelete = `${serverUrl}/product-group/`;

export {
  serverUrl,
  loginUrl,
  createNewsUrl,
  getArticlesUrl,
  editArticlesUrl,
  deleteArticlesUrl,
  createFaqsUrl,
  getFaqsUrl,
  editFaqsUrl,
  deleteFaqsUrl,
  createStoreUrl,
  getStoresUrl,
  editStoreUrl,
  deleteStoreUrl,
  createRubricUrl,
  getRubricsUrl,
  editRubricUrl,
  deleteRubricUrl,
  createPriceUrl,
  getPricesUrl,
  editPriceUrl,
  deletePriceUrl,
  createMechanicUrl,
  getMechanicsUrl,
  editMechanicUrl,
  deleteMechanicUrl,
  createPromosUrl,
  getPromosUrl,
  editPromosUrl,
  deletePromosUrl,
  getSettingsUrl,
  editSettingsUrl,
  getUserUrl,
  editUserUrl,
  smsUrl,
  createCategoryStockUrl,
  editCategoryStockUrl,
  deleteCategoryStockUrl,
  allCategoryStockUrl,
  marketPropsListUrl,
  marketPropsAutocompleteUrl,
  marketPropertyItemUrl,
  marketCreatePropertyUrl,
  marketCreateMenuUrl,
  marketMenuItemUrl,
  marketMenuListUrl,
  marketMenuListAutocompleteUrl,
  marketMenuSharesCreateUrl,
  marketSharesListUrl,
  marketShareEditUrl,
  marketSharesDelete,
  marketProductsListUrl,
  marketProductSave,
  marketProductsDelete,
  marketProductGroupSave,
  marketProductGroupList,
  marketProductsGroupDelete
};
