import api from "@/plugins/api";
import {
  marketPropsAutocompleteUrl,
  marketPropertyItemUrl
} from "@/store/urls";

function autocompleteList({ commit }, data) {
  return new Promise((resolve, reject) => {
    api
      .get(marketPropsAutocompleteUrl, { params: data })
      .then(response => {
        if (response.status === 200) {
          commit("setAutocompleteProps", response.data);
          resolve(response);
        } else {
          reject(response.data.message);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

function loadItem({ commit }, data) {
  return new Promise((resolve, reject) => {
    api
      .get(marketPropertyItemUrl + data.id)
      .then(response => {
        if (response.status === 200) {
          commit("setSelectedItem", response.data);
          resolve(response);
        } else {
          reject(response.data.message);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
export { autocompleteList, loadItem };
