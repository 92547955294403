<template>
  <v-row class="mb-6">
    <v-col v-show="blockLeft.showBlock" class="col-sm-6">
      <v-card>
        <v-row style="margin: 0;">
          <v-col class="col-sm-12">
            <v-card-title>СПИСОК ГРУПП</v-card-title>
          </v-col>
        </v-row>
        <hr />
        <v-btn style="margin-top: 20px;" color="green white--text">
          <span @click="addGroup()">Добавить группу</span>
        </v-btn>
        <v-data-table
          style="cursor: pointer;"
          :headers="headersProductsGroupList"
          :single-select="true"
          item-key="id"
          :items="productsGroupList"
          :loading="loading"
          v-model="selectedRowsProductsGroupList"
          loading-text="Загрузка..."
          :search="search"
        >
          <template v-slot:item="{ item, index }">
            <tr
              @click="productsGroupClicked(item, index)"
              :key="'productsGroupList_' + item.id"
              :class="
                selectedRows.indexOf(item.id) > -1 ? 'blue--lighten-5' : ''
              "
              style="margin-top: 50px!important;"
            >
              <td style="width: 25%!important;margin-top: 50px;">
                {{ index + 1 }}
              </td>
              <td>
                {{ item.id }}
              </td>
              <td style="width: 70%!important;">
                <p>{{ item.name }}</p>
                <p>{{ item.description }}</p>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col v-show="blockCenter.showBlock" class="col-sm-6">
      <v-card
        min-width="300"
        tile
        v-bind:class="{
          'height-screen': true,
          'green--lighten-5': mode === 'add',
          'blue--lighten-5':
            productsGroupItem !== undefined && productsGroupItem !== null
        }"
      >
        <v-row style="margin: 0;">
          <v-col class="col-sm-12">
            <v-card-title>ИНТЕРФЕЙС ГРУПП</v-card-title>
          </v-col>
        </v-row>
        <hr />
        <v-col class="col-sm-12">
          <v-text-field
              rows="1"
              auto-grow
              v-model="productsGroupItem.name"
              label="Наименование"
              placeholder="Наименование группы"
          ></v-text-field>
          <v-textarea
              v-model="productsGroupItem.description"
              label="Описание"
              placeholder="Описание группы"
              filled
              auto-grow
              rows="4"
              row-height="30"
          ></v-textarea>
        </v-col>
        <v-row style="margin-top: 7px;">
            <v-col
                v-for="(item, index) in productItem.files"
                :key="index"
                style="text-align: center;"
                class="col-sm-3"
            >
              <v-card style="display: inline-block; margin-left: 20px;">
                <v-img
                    :key="index"
                    max-height="200"
                    max-width="200"
                    :src="item.path"
                ></v-img>
              </v-card>
            </v-col>

        </v-row>

        <v-col class="col-sm-12">
          <v-text-field
            rows="1"
            auto-grow
            disabled
            v-model="productItem.name"
            label="Наименование"
            placeholder="Наименование товара"
          ></v-text-field>
          <v-textarea
            v-model="productItem.description"
            label="Описание"
            placeholder="Описание товара"
            filled
            disabled
            auto-grow
            rows="4"
            row-height="30"
          ></v-textarea>
          <v-row>
            <v-col class="col-sm-6">
              <v-text-field
                rows="1"
                auto-grow
                disabled
                v-model="productItem.price"
                label="Цена"
                placeholder="Цена товара"
              ></v-text-field>
            </v-col>
            <v-col class="col-sm-6">
              <v-text-field
                rows="1"
                auto-grow
                disabled
                v-model="productItem.balance"
                label="В наличии"
                placeholder="Количество товаров"
              ></v-text-field>
            </v-col>
          </v-row>
          <h3 v-if="mode != ''">Свойства</h3>
          <template v-for="(item, index) in filerProperties">
            <v-row v-bind:key="'filter' + index">
              <v-col class="col-sm-2">
                {{ filerProperties[index].propertyName }}</v-col
              >
              <v-col class="col-sm-10">
                <v-card-text>
                  <v-autocomplete
                    :multiple="true"
                    style="margin-top: -40px;"
                    :items="filerProperties[index].properties"
                    v-model="filerProperties[index].modal"
                    chips
                    deletable-chips
                    item-text="name"
                    item-value="properties"
                    hide-no-data
                    hide-selectedpg_catalog
                    label="Свойство"
                    return-object
                  ></v-autocomplete>
                </v-card-text>
              </v-col>
            </v-row>
          </template>

          <v-btn
            v-show="blockCenter.showButtonAddProducts"
            color="green white--text"
            style="margin-top: 20px;"
          >
            <span @click="addProducts()">Добавить товары</span>
          </v-btn>

          <v-card
            style="width: 100%"
            v-for="(item, index) in selectedProducts"
            v-bind:key="'card_selected_product_' + index"
            @click="viewProductItem(item)"
          >
            <v-col style="margin-top: 25px;" class="col-sm-12">
              <v-row>
                <v-col class="col-sm-1">
                  {{ index + 1 }}
                </v-col>
                <v-col class="col-sm-1">
                  {{ item.id }}
                </v-col>
                <v-col class="col-sm-9">
                  <p>{{ item.name }}</p>
                  <p>{{ item.description }}</p>
                </v-col>
                <v-col style="cursor: pointer" class="col-sm-1">
                  <v-icon
                    @click="deleteProduct(item, index)"
                    style="float: right;color: red;cursor: pointer;font-size: 30px;z-index: 999"
                  >
                    mdi-delete
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-card>

          <v-row v-if="mode != ''" style="margin-top: 25px;">
            <v-col class="col-sm-12">
              <v-btn
                @click="saveProductsInGroup()"
                style="float: right;"
                color="green white--text"
              >
                <span>Сохранить</span>
              </v-btn>
              <v-btn
                v-if="mode == 'edit'"
                @click="deleteProductsInGroup(true)"
                style="float: right;margin-right: 20px;"
                color="red white--text"
              >
                <span>Удалить</span>
              </v-btn>
              <v-dialog
                v-model="showModalDeleteGroup"
                persistent
                max-width="600px"
              >
                <v-card>
                  <v-card-title style="display: block;text-align: center;">
                    <span class="headline"
                      >Вы уверены что хотите удалить группу?</span
                    >
                  </v-card-title>
                  <v-card-actions style="display: block;text-align: center;">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-1"
                      @click="deleteProductsInGroup(false)"
                      >Да</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      @click="showModalDeleteGroup = false"
                      >Нет
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-col>
    <v-col v-show="blockRight.showBlock" class="col-sm-6">
      <v-card>
        <v-col class="col-sm-12">
          <button
            style="float: right;"
            type="button"
            class="ma-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default orange darken-2"
            @click="backToListGroup()"
          >
            <span class="v-btn__content"
              ><i
                aria-hidden="true"
                class="v-icon notranslate v-icon--left mdi mdi-arrow-left theme--dark"
              ></i
              >Назад
            </span>
          </button>
        </v-col>

        <v-col class="col-sm-12">
          <filter-properties-component></filter-properties-component>
        </v-col>

        <v-data-table
          style="cursor: pointer;"
          :headers="headers"
          :single-select="true"
          item-key="id"
          :items="productsList"
          :loading="loading"
          v-model="selectedRows"
          loading-text="Загрузка..."
          :search="search"
        >
          <template v-slot:item="{ item, index }">
            <tr
              :key="item.id"
              :class="
                selectedRows.indexOf(item.id) > -1 ? 'blue--lighten-5' : ''
              "
              style="margin-top: 50px!important;"
            >
              <td style="width: 25%!important;margin-top: 50px;">
                <v-card
                  v-if="item.files.hasOwnProperty(0)"
                  style="display: inline-block; margin-left: 20px;"
                >
                  <v-img
                    max-height="200"
                    max-width="200"
                    :src="item.files[0].path"
                  >
                  </v-img>
                </v-card>
              </td>
              <td style="width: 70%!important;">
                <p>{{ item.name }}</p>
                <p>{{ item.description }}</p>
                <!--                <h3>В наличии {{ item.balance }}</h3>-->
              </td>
              <td style="width: 5%!important;">
                {{ item.price }}
              </td>
              <td>
                <v-checkbox
                  @click="productClicked(item, true)"
                  :key="'productsList_' + item.id"
                  style="padding-top: 110px;"
                  v-model="item.is_activeInGroup"
                  class="mx-2"
                  label="Активность"
                ></v-checkbox>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
//import { mapState } from "vuex";
import FilterPropertiesComponent from "./FilterPropertiesComponent";
import { mapState } from "vuex";

export default {
  name: "ProductsGroupComponent",
  components: { FilterPropertiesComponent },
  data() {
    return {
      filterPropertyModal: [],
      deleteProducts: [],
      mode: "",
      showModalDeleteGroup: false,
      productsListAdditional: [],
      filerProperties: {},
      blockSelectFilter: {},
      search: null,
      filter: [],
      propertiesFilter: [],
      selectedRows: [],
      selectedRowsProductsGroupList: [],
      selectedRowsProducts: [],
      loading: false,
      selectedProducts: [],
      selectedProductsCount: [],
      headersProductsGroupList: [
        { text: "", value: "" },
        { text: "id", value: "id" },
        { text: "Название", value: "name" }
      ],
      headersSelectedProducts: [
        { text: "", value: "" },
        { text: "id", value: "id" },
        { text: "Название", value: "alias" }
      ],
      headers: [
        { text: "Картинка", value: "name" },
        { text: "Название", value: "alias" },
        { text: "Цена", value: "date[0]", filterable: false },
        { text: "", value: "" }
      ],
      blockLeft: {
        showBlock: true
      },
      blockCenter: {
        showBlock: true,
        showButtonAddProducts: false
      },
      blockRight: {
        showBlock: false
      },
      productItem: {

      },
      productsGroupItem: {
        name: "",
        description: "",
        is_active: false
      }
    };
  },
  methods: {
    viewProductItem(item) {
      this.productItem = item;
      this.propertiesForView(item, true);

    },
    backToListGroup() {
      this.mode = "edit";
      this.blockLeft = {
        showBlock: true
      };
      this.blockCenter = {
        showBlock: true,
        showButtonAddProducts: true
      };
      this.blockRight = {
        showBlock: false
      };
    },
    deleteProductsInGroup(stepDelete) {
      this.showModalDeleteGroup = stepDelete;
      if (stepDelete == false) {
        this.$store.dispatch("marketProductsGroups/deleteProductsInGroup", {
          id: this.productsGroupItem.id
        });

        let _this = this;
        this.$store.state.marketProductsGroups.productsGroupList = this.$store.state.marketProductsGroups.productsGroupList.filter(
          function(group) {
            return _this.productsGroupItem.id != group.id;
          }
        );
        // this.getListProductsGroup();
      }
    },
    deleteProduct(item) {
      this.deleteProducts.push(item);
      item.is_activeInGroup = false;
      this.productClicked(item);
    },
    toggleSelection(keyID) {
      this.selectedRows = [];
      this.selectedRows.push(keyID);
    },
    productsGroupClicked(item) {
      this.mode = "productsGroupClicked";
      this.blockCenter.showButtonAddProducts = true;
      this.filerProperties = [];
      this.productItem = [];
      this.selectedProducts = [];
      this.productsGroupItem = item;
      //eslint-disable-next-line no-console
      console.log(this.productsGroupItem)
      this.toggleSelection(item.id);

      let ids = [];
      for (let id in item.products) {
        ids.push(item.products[id].id);
      }

      this.getListProducts({ ids: ids });
    },
    saveProductsInGroup() {
      if (this.selectedProducts.length == 0) {
        this.mode == "add"
          ? alert("Не выбраны товары для создания группы")
          : alert("Выберите хотябы один товар либо удалите группу");
        return;
      }

      let result = [];
      this.$store
        .dispatch("marketProductsGroups/saveGroupProduct", {
          selectedProduct: this.selectedProducts,
          productsGroupItem: this.productsGroupItem,
          deleteProducts: this.deleteProducts
        })
        .then(response => {
          result = response.result.data;
        })
        .finally(() => {
          if (this.mode == "add") {
            result.productsGroupItem.products = [];
            result.productsGroupItem.products = result.selectedProduct;
            this.$store.state.marketProductsGroups.productsGroupList.unshift(
              result.productsGroupItem
            );
            this.productsGroupItem = result.productsGroupItem;
            this.selectedRows = [];
            this.selectedRows.push(result.productsGroupItem.id);
            this.backToListGroup();
          }
        });
    },
    propertiesForView(array = [], onProduct = false) {
      let propertiesResult = [];
      let pn_propertiesFilterId = [];
      let pn_propertiesFilterName = [];


      if (onProduct == true) {
        let array2 = [];
        array2.push(array)
        array = array2;
      }

      for (let i in array) {
        array[i].properties.forEach(function(property) {
          propertiesResult.push({
            propertyPId: property.p_id,
            propertyPName: property.p_name,
            pn_propertiesFilterId: property.pn_id,
            pn_propertiesFilterName: property.pn_name
          });
          pn_propertiesFilterId.push(property.pn_id);
          pn_propertiesFilterName.push(property.pn_name);
        });
      }
      pn_propertiesFilterId = [...new Set(pn_propertiesFilterId)];
      pn_propertiesFilterName = [...new Set(pn_propertiesFilterName)];

      let result = [];
      for (let i in propertiesResult) {
        if (i == 0) {
          result.push({
            idproperty: propertiesResult[i].propertyPId,
            nameProperty: propertiesResult[i].propertyPName,
            idPropertyName: propertiesResult[i].pn_propertiesFilterId,
            namePropertyName: propertiesResult[i].pn_propertiesFilterName
          });
          continue;
        }

        let flag = false;
        for (let j in result) {
          if (result[j].idproperty == propertiesResult[i].propertyPId) {
            flag = true;
            break;
          }
        }
        if (flag == false) {
          result.push({
            idproperty: propertiesResult[i].propertyPId,
            nameProperty: propertiesResult[i].propertyPName,
            idPropertyName: propertiesResult[i].pn_propertiesFilterId,
            namePropertyName: propertiesResult[i].pn_propertiesFilterName
          });
        }
      }

      let test = {};
      for (let j5 in pn_propertiesFilterId) {
        test[pn_propertiesFilterId[j5]] = {
          propertyNameId: pn_propertiesFilterId[j5],
          propertyName: pn_propertiesFilterName[j5],
          properties: [],
          modal: []
        };
      }

      for (let j7 in result) {
        test[result[j7].idPropertyName].modal.push({
          id: result[j7].idproperty,
          name: result[j7].nameProperty
        });
        test[result[j7].idPropertyName].properties.push({
          id: result[j7].idproperty,
          name: result[j7].nameProperty
        });
      }

      this.filerProperties = [];
      this.filerProperties = test;
    },
    productClicked(item, fromList = false) {
      if (item.is_activeInGroup == true) {
        this.selectedProducts.push(item);
        if (fromList == true) {
          this.productItem = item;
        }
      } else {
        for (let i in this.selectedProducts) {
          if (this.selectedProducts[i].id == item.id) {
            this.selectedProducts.splice(i, 1);
            this.productItem = [];
          }
        }
      }

      this.propertiesForView(this.selectedProducts);
    },
    addProducts() {
      this.blockLeft.showBlock = false;
      this.blockRight.showBlock = true;
      this.blockCenter.showButtonAddProducts = false;
    },
    addGroup() {
      this.mode = "add";
      this.blockCenter.showButtonAddProducts = true;
      this.productsGroupItem = {
        name: "",
        description: "",
        is_active: false
      };
      this.selectedProducts = [];
      this.filerProperties = [];
      this.productItem = {};

      for (let i in this.$store.state.marketProducts) {
        this.$store.state.marketProducts[i] = [];
      }

      //this.$store.dispatch("marketProducts/list", null);
    },
    getListProducts(filterParameters = {}) {
      this.loading = true;
      this.productsItem = [];
      let result = [];
      this.$store
        .dispatch("marketProducts/list", filterParameters)
        .then(response => {
          result = response.result;
        })
        .finally(() => {
          // if (this.mode == 'edit') {
          //   result.forEach(function(itemProduct) {
          //
          //   }
          // }

          if (this.mode == "productsGroupClicked") {
            let _this = this;
            result.forEach(function(itemProduct) {
              itemProduct.is_activeInGroup = true;
              _this.productClicked(itemProduct);
            });
            this.mode = "edit";
          }
          this.loading = false;
        });
    },
    getListProductsGroup() {
      this.$store.dispatch("marketProductsGroups/list").finally(() => {});
    }
  },
  computed: {
    ...mapState({
      productsList: function(state) {
        if (this.mode == "edit") {
          let i2 = 1;
          let _this = this;
          state.marketProducts.products.forEach(function(product) {
            for (let i in _this.selectedProducts) {
              if (_this.selectedProducts[i].id == product.id) {
                product.is_activeInGroup = true;
                i2++;
              }
            }

            if (i2 == _this.selectedProducts.length) {
              return;
            }
          });
        }
        return state.marketProducts.products;
      },
      productsGroupList: state => state.marketProductsGroups.productsGroupList
    })
  },

  mounted() {
    let _this = this;
    this.$root.$on("filterPropertiesList", function(filter = []) {
      _this.filter = filter;
      _this.getListProducts({ search: filter });
    });
    this.getListProductsGroup();
  }
};
</script>

<style scoped>
.preloader {
  top: 25%;
  left: 50%;
  position: absolute;
  z-index: 100;
}
.blockOpacety {
  opacity: 0.5;
}
.self-style {
  height: 100%;
}
.self-style >>> .v-icon {
  font-size: 150px;
  height: 100%;
  width: 100%;
}
.self-style >>> .v-input__prepend-outer {
  width: 100%;
  height: 100%;
}

.self-style >>> .v-input__icon {
  height: 100%;
}
</style>

<style>
.blue--lighten-5 {
  background-color: #e3f2fd !important;
}
.green--lighten-5 {
  background-color: #e8f5e9 !important;
}
.height-screen {
  height: 100vh;
  overflow: scroll;
}
</style>
