<template>
  <v-row class="mb-6">
    <v-col class="col-sm-6">
      <v-card>
        <v-row style="margin: 0;">
          <v-col class="col-sm-4">
            <v-card-title>СПИСОК АКЦИЙ</v-card-title>
          </v-col>
          <v-col class="col-sm-8">
            <v-btn
              style="float: right;margin-right: 20px;"
              color="green white--text"
            >
              <span @click="createNewShare()">Добавить +</span>
            </v-btn>
          </v-col>
        </v-row>

        <hr />
        <v-card-text
          ><v-text-field
            placeholder="Имя / псевдонимы акций"
            append-icon="mdi-magnify"
            v-model="search"
          ></v-text-field>
        </v-card-text>
        <v-data-table
          :headers="headers"
          :single-select="true"
          item-key="id"
          :items="sharesList"
          :loading="loading"
          v-model="selectedRows"
          loading-text="Загрузка..."
          :search="search"
        >
          <template v-slot:item="{ item }">
            <tr
              :key="item.id"
              :class="
                selectedRows.indexOf(item.id) > -1 ? 'blue--lighten-5' : ''
              "
              @click="shareClicked(item)"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.alias }}</td>
              <td>{{ item.date[0] }}</td>
              <td>{{ item.date[1] }}</td>
              <!--              <td>{{ actiiveText[item.isActive] }}</td>-->
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col class="col-sm-6">
      <v-card
        min-width="300"
        tile
        v-bind:class="{
          'height-screen': true,
          'green--lighten-5': mode === 'create',
          'blue--lighten-5': selectedItem !== undefined && selectedItem !== null
        }"
      >
        <v-row style="margin: 0">
          <v-col class="col-sm-6">
            <v-card-title v-show="!newCreateShare">ОПИСАНИЕ АКЦИИ</v-card-title>
            <v-card-title v-show="newCreateShare">СОЗДАНИЕ АКЦИИ</v-card-title>
          </v-col>
          <v-col class="col-sm-6">
            <v-alert
              style="margin-bottom: 0;margin-top: 10px;"
              :value="loadingEffectAnimation.alert"
              :type="loadingEffectAnimation.responseAlert"
              dense
              text
              transition="scale-transition"
            >
              {{
                loadingEffectAnimation.textAlert[
                  loadingEffectAnimation.responseAlert
                ]
              }}
            </v-alert>
          </v-col>
        </v-row>
        <hr />
        <v-progress-circular
          v-show="loadingEffectAnimation.preloader"
          :size="50"
          color="primary"
          indeterminate
          class="preloader"
        ></v-progress-circular>

        <v-col
          cols="12"
          sm="12"
          md="12"
          :class="{ blockOpacety: loadingEffectAnimation.preloader }"
          v-show="newOrEditShare"
        >
          <v-text-field
            rows="1"
            auto-grow
            :error="error.name"
            v-model="sharesItem.name"
            label="Название акции"
            placeholder="Введите название акции"
          ></v-text-field>

          <v-text-field
            rows="1"
            auto-grow
            v-model="sharesItem.alias"
            label="Псевдоним"
            placeholder="Введите название псевдонима"
          ></v-text-field>

          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="sharesItem.date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Период действия"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <span>с: {{ sharesItem.date[0] }}</span>
                  <span style="margin-left: 20px;">
                    по: {{ sharesItem.date[1] }}
                  </span>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="sharesItem.date"
              scrollable
              range
              locale="ru-RU"
              :weekday-format="weekDay"
              :title-date-format="titleDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(sharesItem.date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-row>
            <v-col
              v-show="!showNullImage"
              style="text-align: center;"
              class="col-sm-7"
            >
              <v-img
                max-height="150"
                max-width="100%"
                :src="sharesItem.image"
              ></v-img>
            </v-col>

            <v-col
              style="text-align: center;"
              class="col-sm-5"
              :class="{ 'col-sm-12': showNullImage }"
            >
              <v-file-input
                @change="onFileChanged"
                v-model="sharesItem.selectedFile"
                label="Изображение"
                filled
                prepend-icon="mdi-camera"
                show-size
                accept=".png, .jpg, .jpeg, .gif"
                style="margin-top: 30px;"
              >
              </v-file-input>
            </v-col>
          </v-row>

          <v-card-text>
            <v-autocomplete
                :disabled="sharesItem.id === 0"
                :loading="loadingShareProperties"
                v-model="sharesItem.properties"
                multiple
                chips
                deletable-chips
                :items="autocompleteItems"
                :search-input.sync="propsSearch"
                item-text="name"
                item-value="id"
                hide-no-data
                hide-selectedpg_catalog
                label="Свойства акции"
                placeholder="Начинайте писать имя свойства"
                return-object
            ></v-autocomplete>
          </v-card-text>

          <v-divider color="#333"></v-divider>
          <v-checkbox
            v-model="sharesItem.isActive"
            class="mx-2"
            label="Активность"
          ></v-checkbox>

          <v-divider color="#333"></v-divider>

          <v-dialog
            v-model="blockShare.deleteShowModal"
            persistent
            max-width="600px"
          >
            <v-card>
              <v-card-title style="display: block;text-align: center;">
                <span class="headline">Удалить акцию?</span>
              </v-card-title>
              <v-card-actions style="display: block;text-align: center;">
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" @click="deleteShare(false)"
                  >Да</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  @click="blockShare.deleteShowModal = false"
                  >Нет
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-row style="margin-top: 25px;float: left">
            <v-col v-if="mode == 'edit'" class="col-sm-6">
              <v-btn color="red white--text">
                <span @click="deleteShare(true)">Удалить</span>
              </v-btn>
            </v-col>
            <v-col class="col-sm-6">
              <v-btn color="green white--text">
                <span @click="saveNewShare()">Сохранить</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProductPropertiesComponent",
  data() {
    return {
      blockShare: {
        deleteShowModal: false
      },
      mode: "",
      modal: false,
      loadingShareProperties: false,
      isSelecting: false,
      headers: [
        { text: "Имя", value: "name" },
        { text: "Имя (Псевдоним)", value: "alias" },
        { text: "Начало акции", value: "date[0]", filterable: false },
        { text: "Конец акции", value: "date[1]", filterable: false }
        // { text: "Активность", value: "isActive", filterable: false }
      ],
      selectedItem: null,
      loading: false,
      loadingItem: false,
      search: null,
      selectedRows: [],
      sharesItem: {
        date: [
          new Date().toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10)
        ],
        alias: "",
        name: "",
        selectedFile: [],
        image: "",
        isActive: false,
        properties: []
      },
      showNullImage: false,
      newCreateShare: false,
      newOrEditShare: false,
      propsSearch: null,
      error: {
        name: false
      },
      actiiveText: {
        true: "Активна",
        false: "Отключена"
      },
      oldImage: "",
      loadingEffectAnimation: {
        preloader: false,
        alert: false,
        responseAlert: "error",
        textAlert: {
          success: "Данные успешно сохранены",
          error:
            "Ошибка! Не удалось сохранить. Проверьте правильность ввода данных"
        }
      }
    };
  },
  methods: {
    deleteShare(stepDelete) {
      this.blockShare.deleteShowModal = stepDelete;
      if (stepDelete == false) {
        this.$store
          .dispatch("marketShares/deleteShare", this.sharesItem)
          .then(response => {
            //eslint-disable-next-line no-console
            console.log(response);
          })
          .finally(() => {
            this.getListShares();
          });
      }
    },
    weekDay(date) {
      return new Date(date).toLocaleString("ru-RU", { weekday: "short" });
    },
    titleDate(dates) {
      return dates
        .map(n =>
          new Date(n).toLocaleString("ru-RU", {
            weekday: "short",
            month: "short",
            day: "numeric"
          })
        )
        .join(" - ");
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged() {
      let reader = new FileReader();
      let _this = this;

      if (this.sharesItem.selectedFile !== null) {
        reader.onload = function(reader) {
          _this.oldImage = _this.sharesItem.image;
          _this.sharesItem.image = reader.target.result;
        };

        reader.readAsDataURL(this.sharesItem.selectedFile);
        _this.showNullImage = false;
      } else {
        if (_this.oldImage !== null) {
          _this.sharesItem.image = _this.oldImage;
          _this.showNullImage = false;
        } else {
          _this.showNullImage = true;
        }
      }
    },
    rulesValidates() {
      for (let i in this.sharesItem) {
        if (this.error.hasOwnProperty(i)) {
          if (this.sharesItem[i] == "" || this.sharesItem[i] == null) {
            this.error[i] = true;
          } else {
            this.error[i] = false;
          }
        }
      }

      for (let i in this.error) {
        if (this.error[i] == true) {
          return false;
        }
      }

      return true;
    },
    getListShares() {
      this.loading = true;

      this.$store
        .dispatch("marketShares/list", { search: this.search })
        .finally(() => {
          this.loading = false;
        });
    },
    shareClicked(item) {
      item.image == null
        ? (this.showNullImage = true)
        : (this.showNullImage = false);

      this.sharesItem = item;
      this.newOrEditShare = true;
      this.newCreateShare = false;
      this.mode = "edit";
      this.selectedItem = item.id;
      this.toggleSelection(item.id);
    },
    toggleSelection(keyID) {
      this.selectedRows = [];
      this.selectedRows.push(keyID);
    },
    createNewShare() {
      this.newOrEditShare = true;
      this.mode = "create";
      this.error = { name: false };
      this.selectedItem = null;
      this.selectedRows = [];
      this.sharesItem = {
        date: [
          new Date().toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10)
        ],
        alias: "",
        name: "",
        selectedFile: [],
        image: null,
        isActive: false
      };
      this.newCreateShare = true;
      this.showNullImage = true;
    },
    saveNewShare() {
      if (this.rulesValidates()) {
        this.loadingAnimation(true);

        if (this.mode == "create") {
          this.$store
            .dispatch("marketShares/saveNewShare", this.sharesItem)
            .then(response => {
              this.loadingEffectAnimation.responseAlert = "success";
              this.getListShares();
              this.shareClicked(response);
            })
            .finally(() => {
              this.loadingAnimation(false);
            });
        } else if (this.mode == "edit") {
          this.$store
            .dispatch("marketShares/editShare", this.sharesItem)
            .then(response => {
              this.loadingEffectAnimation.responseAlert = "success";
              this.getListShares();
              this.shareClicked(response);
            })
            .catch(error => {
              // eslint-disable-next-line no-console
              console.log(error);
            })
            .finally(() => {
              this.loadingAnimation(false);
            });
        }
      }
    },
    loadingAnimation(load) {
      this.loadingEffectAnimation.preloader = load;

      if (load == false) {
        this.loadingEffectAnimation.alert = true;

        setTimeout(() => {
          this.loadingEffectAnimation.alert = false;
        }, 2100);
      }
    },
    deleteProp(item) {
      if (confirm('Удалить значение "' + item.name + '"?')) {
        item.isDeleted = true;
      }
    },
    getAutocompleteProps() {
      this.$store.dispatch("marketProps/autocompleteList")
    },
    getListProps() {
      this.$store.dispatch("marketProps/list");
    },
  },
  computed: {
    ...mapState({
      sharesList: state => state.marketShares.shares,
      autocompleteItems: (state) => {
        let items = [];
        let alias = '';
        let aliasName = '';

        state.marketProps.props.forEach(function (property) {
          alias = ' [' + (property.alias == null ? property.name : property.alias) + ']';
          aliasName = property.propertyName.alias == null ? property.propertyName.name : property.propertyName.alias;

          items.push({
            "id": property.id,
            "name": aliasName + alias
          })
        })
        return items
      }
    }),

  },
  mounted() {
    this.getListShares();
    // this.getAutocompleteProps();
    this.getListProps();

  }
};
</script>

<style scoped>
.preloader {
  top: 25%;
  left: 50%;
  position: absolute;
  z-index: 100;
}
.blockOpacety {
  opacity: 0.5;
}
.self-style {
  height: 100%;
}
.self-style >>> .v-icon {
  font-size: 150px;
  height: 100%;
  width: 100%;
}
.self-style >>> .v-input__prepend-outer {
  width: 100%;
  height: 100%;
}

.self-style >>> .v-input__icon {
  height: 100%;
}
</style>

<style>
.blue--lighten-5 {
  background-color: #e3f2fd !important;
}
.green--lighten-5 {
  background-color: #e8f5e9 !important;
}
.height-screen {
  height: 100vh;
  overflow: scroll;
}
</style>
