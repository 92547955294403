var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.name !== 'MainComponentSMS')?_c('div',{staticClass:"main-component-left-nav pr-1 pl-2"},[(
      [
        'MainComponentFaq',
        'MainComponentShop',
        'MainComponentHeading',
        'MainComponentCatStock',
        'MainComponentShareMechs'
      ].indexOf(_vm.$route.name) < 0
    )?_c('div',{staticClass:"filter-wrap"},[_c('v-row',{staticClass:"ml-0 mr-0"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"dark":"","items":_vm.years,"label":"Год"},on:{"input":_vm.filterList},model:{value:(_vm.filter.year),callback:function ($$v) {_vm.$set(_vm.filter, "year", $$v)},expression:"filter.year"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"dark":"","item-text":"title","item-value":"value","items":_vm.months,"label":"Месяц"},on:{"input":_vm.filterList},model:{value:(_vm.filter.month),callback:function ($$v) {_vm.$set(_vm.filter, "month", $$v)},expression:"filter.month"}})],1)],1),_c('v-row',{staticClass:"ml-0 mr-0 mb-4"},[(_vm.$route.name === 'MainComponentNews')?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"dark":"","hide-details":"","item-text":"title","item-value":"value","items":[
            { title: 'Новость', value: 1 },
            { title: 'Статья', value: 2 }
          ],"label":"Тип"},on:{"input":_vm.filterList},model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}})],1):_vm._e()],1)],1):_vm._e(),(['MainComponentPromo', 'MainComponentPosition'].indexOf(_vm.$route.name) > -1)?_c('div',{staticClass:"params"},[(_vm.$route.name === 'MainComponentPromo')?_c('v-select',{attrs:{"items":_vm.schemas,"item-text":"title","item-value":"stockSchemaid","loading":_vm.selectLoading,"disabled":_vm.selectLoading,"label":"Механика","outlined":""},model:{value:(_vm.localPromo),callback:function ($$v) {_vm.localPromo=$$v},expression:"localPromo"}}):(_vm.$route.name === 'MainComponentPosition')?_c('v-select',{attrs:{"items":_vm.rubrics,"item-text":"title","item-value":"rubricid","loading":_vm.selectLoading,"disabled":_vm.selectLoading,"label":"Рубрика","outlined":""},model:{value:(_vm.localRubric),callback:function ($$v) {_vm.localRubric=$$v},expression:"localRubric"}}):_vm._e()],1):_vm._e(),(_vm.$route.name === 'MainComponentNews')?_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.articles),function(article,key){return _c('v-list-item',{key:key,staticClass:"elevation-3 mb-1",class:{
        active: _vm.$store.state.news.oneNews.articleid === article.articleid
      },attrs:{"link":""},on:{"click":function($event){return _vm.setArticle(article)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(article.articletitleShort)+" ")])],1)],1)}),1):(_vm.$route.name === 'MainComponentFaq')?_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.faqs),function(faq,key){return _c('v-list-item',{key:key,staticClass:"elevation-3 mb-1",class:{ active: _vm.$store.state.faqs.oneFaqs.faqid === faq.faqid },attrs:{"link":""},on:{"click":function($event){return _vm.setFaq(faq)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(faq.question)+" ")])],1)],1)}),1):(_vm.$route.name === 'MainComponentShop')?_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.stores),function(store,key){return _c('v-list-item',{key:key,staticClass:"elevation-3 mb-1",class:{
        active: _vm.$store.state.shop.oneStore.storeid === store.storeid
      },attrs:{"link":""},on:{"click":function($event){return _vm.setStore(store)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(store.storeName)+" ")])],1)],1)}),1):(_vm.$route.name === 'MainComponentHeading')?_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.rubrics),function(rubric,key){return _c('v-list-item',{key:key,staticClass:"elevation-3 mb-1",class:{
        active: _vm.$store.state.heading.oneRubric.rubricid === rubric.rubricid
      },attrs:{"link":""},on:{"click":function($event){return _vm.setRubric(rubric)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(rubric.title)+" ")])],1)],1)}),1):(_vm.$route.name === 'MainComponentCatStock')?_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.categoryStocks),function(category,key){return _c('v-list-item',{key:key,staticClass:"elevation-3 mb-1",class:{
        active: _vm.$store.state.catStock.oneCategoryStock.id === category.id
      },attrs:{"link":""},on:{"click":function($event){return _vm.setCategoryStock(category)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(category.name)+" ")])],1)],1)}),1):(_vm.$route.name === 'MainComponentPosition')?_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.prices),function(price,key){return _c('v-list-item',{key:key,staticClass:"elevation-3 mb-1",class:{
        active:
          _vm.$store.state.heading.oneSpecPrice.specPriceid === price.specPriceid
      },attrs:{"link":""},on:{"click":function($event){return _vm.setPrice(price)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(price.description)+" ")])],1)],1)}),(_vm.prices && !_vm.prices.length)?_c('div',{staticClass:"empty-text text-center align-center"},[_vm._v(" В этой рубрике еще нет ни одной спец цены ")]):_vm._e()],2):(_vm.$route.name === 'MainComponentPromo')?_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.promos),function(promo,key){return _c('v-list-item',{key:key,staticClass:"elevation-3 mb-1",class:{
        active: _vm.$store.state.mechanics.oneStock.stockid === promo.stockid
      },attrs:{"link":""},on:{"click":function($event){return _vm.setPromo(promo)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(promo.description)+" ")])],1)],1)}),(_vm.promos && !_vm.promos.length)?_c('div',{staticClass:"empty-text text-center align-center"},[_vm._v(" В этой механике еще нет ни одной акции ")]):_vm._e()],2):(_vm.$route.name === 'MainComponentShareMechs')?_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.schemas),function(schema,key){return _c('v-list-item',{key:key,staticClass:"elevation-3 mb-1",class:{
        active:
          _vm.$store.state.mechanics.oneSchema.stockSchemaid ===
          schema.stockSchemaid
      },attrs:{"link":""},on:{"click":function($event){return _vm.setSchema(schema)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(schema.title)+" ")])],1)],1)}),1):_vm._e()],1):_c('div',{staticClass:"main-component-left-nav pr-1 pl-2"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"elevation-3 mb-1",class:{
        active: _vm.$route.name === 'MainComponentSMS'
      },attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({ name: 'MainComponentSMS' })}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Push уведомления ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }