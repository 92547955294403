<template>
  <v-row class="mb-6">
    <v-col class="col-sm-6">
      <v-card>
        <v-card-title>
          МЕНЮ
          <v-spacer></v-spacer>
        </v-card-title>
        <hr />
        <v-card-text class="overflow-auto" style="height: calc(100vh - 195px); min-height: 450px">
          <div v-if="loading" class="text-center">Загрузка...</div>
          <v-treeview
              v-if="!loading"
              open-all
              activatable
              :active.sync="treeItemActive"
              :items="marketMenu"
          >
            <template slot="label" slot-scope="{ item }">
                <a
                    class="pt-2 pb-2 d-block"
                    :class="{
                    'green--text': item.id === null,
                    'grey--text': item.isActive === false
                  }"
                    @click="selectMenuItem(item)"
                >{{ item.name }}</a>
            </template>

            <template slot="append" slot-scope="{ item }">
              <small
                  v-if="item.id !== 0"
                  :class="{
                    'green--text': item.id === null,
                    'grey--text': item.isActive === false
                  }"
              >[{{ item.ord }}]</small>
            </template>

          </v-treeview>
        </v-card-text>

      </v-card>
    </v-col>
    <v-col v-if="treeItemActive.length > 0" class="col-sm-6" style="position: fixed; right:0; ">
      <v-card min-width="300" tile v-bind:class="{
        'green--lighten-5': selectedMenuItem.id !== undefined && selectedMenuItem.id === null,
        'blue--lighten-5': selectedMenuItem.id !== undefined && selectedMenuItem.id !== null
      }">
        <v-card-title>
          <span v-if="selectedMenuItem.id !== undefined && selectedMenuItem.id === null && selectedMenuItem.parent"><small class="grey--text">Добавить в</small> {{ selectedMenuItem.parent.name }}</span>
          <span v-else><small class="grey--text">Редактировать</small> {{ selectedMenuItem.name }}</span>
        </v-card-title>
        <hr />
<!--        <v-card-subtitle class="font-weight-bold">Имя</v-card-subtitle>-->
        <v-card-text>
          <v-autocomplete
              :disabled="selectedMenuItem.id === 0"
              :loading="loadingItem"
              v-model="selectedMenuItem.parent"
              chips
              deletable-chips
              :items="menuAutocompleteItems"
              item-text="name"
              item-value="id"
              hide-no-data
              hide-selectedpg_catalog
              label="Родитель"
              return-object
          ></v-autocomplete>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col class="col-sm-12"
              ><v-text-field
                :disabled="selectedMenuItem.id === 0"
                label="Имя"
                :loading="loadingItem"
                v-model="selectedMenuItem.name"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-text-field
              v-model="selectedMenuItem.ord"
              label="Приоритет"
              type="number"
              :loading="loadingItem"
              :disabled="selectedMenuItem.id === 0"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-autocomplete
              :disabled="selectedMenuItem.id === 0"
              :loading="loadingItem"
              v-model="selectedMenuItem.properties"
              multiple
              chips
              deletable-chips
              :items="autocompleteItems"
              :search-input.sync="propsSearch"
              item-text="name"
              item-value="id"
              hide-no-data
              hide-selectedpg_catalog
              label="Свойства пункта меню"
              placeholder="Начинайте писать имя свойства"
              return-object
          ></v-autocomplete>

          <v-col class="col-sm-12">
            <v-switch
                :disabled="selectedMenuItem.id === 0"
                :loading="loadingItem"
                label="Активен"
                v-model="selectedMenuItem.isActive"
            ></v-switch>
          </v-col>
        </v-card-text>

        <v-card-actions>
          <v-btn color="green white--text" style="margin-right: 0 !important;"
                 v-if="selectedMenuItem.id !== null && !(selectedMenuItem.level >= 3 || treeItemActive.length === 0)"
                 @click="newMenuItem()"
          >+ Добавить подпункт</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="selectedMenuItem.id > 0" color="red white--text" @click="deleteItemMenu()">Удалить</v-btn>
          <v-btn v-if="selectedMenuItem.id !== 0" :color="(selectedMenuItem.id === null ? 'green' : 'blue')+' white--text'" :disabled="loadingItem">
            <span v-if="selectedMenuItem.id === null" @click="createItemMenu()">Создать</span>
            <span v-else @click="saveItemMenu()">Сохранить</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MenuComponent",
  data() {
    return {
      headers: [
        {
          text: "Имя",
          // align: 'start',
          // sortable: false,
          value: "propertyName.name"
        },
        {text: "Имя (Псевдоним)", value: "propertyName.alias"},
        {text: "Значение", value: 'name'},
        {text: "Значение (Псевдоним)", value: 'alias'}
      ],
      items: [
        {
          id: 0,
          name: 'Корень меню',
          level: 0,
          isActive: true,

          children: []
        }
      ],
      loading: false,
      loadingItem: false,
      search: null,
      selectedMenuItem: {},
      itemsPropsSelected: null,
      propsSearch: null,
      treeItemActive: []
    };
  },
  methods: {
    getMenu() {
      this.loading = true;

      this.$store
        .dispatch("marketMenu/list")
        // .then(response => {
        // })
        .finally(() => {
          this.loading = false;
        });
    },
    getListProps() {
      this.$store.dispatch("marketProps/list");
    },
    getAutocompleteProps() {
      this.$store.dispatch("marketProps/autocompleteList")
    },
    getAutocompleteMenu() {
      this.$store.dispatch("marketMenu/listAutocomplete")
    },
    getItemProp(id) {
      this.loadingItem = true;

      this.$store
        .dispatch("marketProps/loadItem", {id: id})
        // .then(response => {
        // })
        .finally(() => {
          this.loadingItem = false;
        });
    },
    newMenuItem() {
      let parent = this.selectedMenuItem;
      this.selectedMenuItem = {
        id: null,
        isActive: true,
        name: null,
        ord: null,
        level: this.selectedMenuItem.level+1,
        children: [],
        properties: [],
        parent: parent
      };
    },
    createItemMenu() {
      this.loadingItem = true;
      this.$store.dispatch("marketMenu/createItemMenu", this.selectedMenuItem)
          .then((result) => {
            this.selectedMenuItem = result.data.result
            this.treeItemActive = [this.selectedMenuItem.id]
          })
          .finally(() => {
            this.loadingItem = false;
          })
      ;
    },
    saveItemMenu() {
      this.loadingItem = true;

      this.$store.dispatch("marketMenu/saveItemMenu", this.selectedMenuItem)
          .finally(() => {
            this.loadingItem = false;
          })
    },
    deleteItemMenu() {
      if (confirm("Удалить \""+this.selectedMenuItem.name+"\"?")) {
        this.loadingItem = true;
        this.$store.dispatch("marketMenu/deleteMenuItem", this.selectedMenuItem)
            .then(() => {
              this.treeItemActive = []
              this.selectedMenuItem = {}
            })
            .finally(() => {
              this.loadingItem = false;
            })
      }
    },
    selectMenuItem(item){
      let items = [];
      // eslint-disable-next-line no-console
      console.log(item)
      for (let i in item.relationProperties) {
        items.push({
          'id': item.relationProperties[i].property.id
        })

      }
      item.properties = items;
      this.selectedMenuItem = item

      let filterParameters = [];
      for (let i in item.relationProperties) {
          filterParameters.push(item.relationProperties[i].property.id);
      }

        if (item.children.length > 0) {
          for (let i in item.children) {
            for (let i2 in item.children[i].relationProperties) {
              filterParameters.push(item.children[i].relationProperties[i2].property.id);
            }

            if (item.children[i].children.length > 0) {
              for (let j in item.children[i].children) {
                for (let i2 in item.children[i].children[j].relationProperties) {
                  filterParameters.push(item.children[i].children[j].relationProperties[i2].property.id);
                }
              }
            }
          }
        }

      // eslint-disable-next-line no-console
      console.log(filterParameters)
      let result = [];
      this.$store
          .dispatch("marketProducts/list", {
            idproperties: filterParameters
          })
          .then(response => {
            result = response.result;
          })
          .finally(() => {
            // eslint-disable-next-line no-console
            console.log(result)
          });
    },
    checkStart(){

    },
    checkEnd(){

    },
  },
  computed: {
    ...mapState({
      marketMenu: (state) => {

        let menu = state.marketMenu.menu

        menu.forEach(function (item, index) {
          let propItems = [];
          item.relationProperties.forEach(function (propItem) {
            propItems.push(propItem.property)
          })
          menu[index].properties = propItems
        })

        return [{
          id: 0,
          name: 'Корень меню',
          level: 0,
          isActive: true,
          children: menu
        }]
      },
      menuAutocompleteItems: (state) => {
        return state.marketMenu.menuAutocomplete
      },
      autocompleteItems: (state) => {
        let items = [];
        let alias = '';
        let aliasName = '';

        state.marketProps.props.forEach(function (property) {

          alias = ' [' + (property.alias == null ? property.name : property.alias) + ']';
          aliasName = property.propertyName.alias == null ? property.propertyName.name : property.propertyName.alias;

          items.push({
            "id": property.id,
            "name": aliasName + alias
          })
        })

        return items
      },
      snackBar: state => state.snackBar
    })
  },
  mounted() {
      this.getMenu();
      this.getAutocompleteProps();
      this.getAutocompleteMenu();
      this.getListProps();
  },
  watch: {
    selectedMenuItem(value) {
      // eslint-disable-next-line no-console
      console.log(value)
    },
    propsSearch(item) {
      // eslint-disable-next-line no-console
      console.log(item)
    }

  },
};
</script>
<style>
.blue--lighten-5 {
  background-color: #E3F2FD !important;
}
.green--lighten-5 {
  background-color: #E8F5E9 !important;
}
.height-screen {
  height: calc(100vh - 64px);
  overflow: scroll;
}
</style>
