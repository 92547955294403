import Vue from "vue";
import VueRouter from "vue-router";

import Auth from "@/views/Auth";
import Main from "@/views/Main";
import MainMarket from "@/views/MainMarket";

import AuthComponentLogin from "@/components/AuthComponent/components/AuthComponentLogin";
import MainComponentNews from "@/components/MainComponent/components/MainComponentNews";
import MainComponentShop from "@/components/MainComponent/components/MainComponentShop";
import MainComponentHeading from "@/components/MainComponent/components/MainComponentHeading";
import MainComponentFaq from "@/components/MainComponent/components/MainComponentFaq";
import MainComponentShareMechs from "@/components/MainComponent/components/MainComponentShareMechs";
import MainComponentSettings from "@/components/MainComponent/components/MainComponentSettings";
import MainComponentUserSettings from "@/components/MainComponent/components/MainComponentUserSettings";
import MainComponentPromo from "@/components/MainComponent/components/MainComponentPromo";
import MainComponentCatStock from "@/components/MainComponent/components/MainComponentCatStock";
import MainComponentPosition from "@/components/MainComponent/components/MainComponentPosition";
import MainComponentMailing from "../components/MainComponent/components/MainComponentMailing";
import MainComponentSMS from "../components/MainComponent/components/MainComponentSMS";

import ProductPropertiesComponent from "../components/MarketComponent/components/ProductPropertiesComponent";
import MenuComponent from "../components/MarketComponent/components/MenuComponent";
import SharesComponent from "../components/MarketComponent/components/SharesComponent";
import ProductsComponent from "../components/MarketComponent/components/ProductsComponent";
import ProductsGroupComponent from "../components/MarketComponent/components/ProductsGroupComponent";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/crm"
  },
  {
    path: "/crm",
    component: Main,
    redirect: { name: "MainComponentNews" },
    children: [
      {
        path: "news",
        name: "MainComponentNews",
        component: MainComponentNews
      },
      {
        path: "shop",
        name: "MainComponentShop",
        component: MainComponentShop
      },
      {
        path: "heading",
        name: "MainComponentHeading",
        component: MainComponentHeading
      },
      {
        path: "faq",
        name: "MainComponentFaq",
        component: MainComponentFaq
      },
      {
        path: "share-mechs",
        name: "MainComponentShareMechs",
        component: MainComponentShareMechs
      },
      {
        path: "settings",
        name: "MainComponentSettings",
        component: MainComponentSettings
      },
      {
        path: "/user-settings",
        name: "MainComponentUserSettings",
        component: MainComponentUserSettings
      },
      {
        path: "promo",
        name: "MainComponentPromo",
        component: MainComponentPromo
      },
      {
        path: "category-stock",
        name: "MainComponentCatStock",
        component: MainComponentCatStock
      },
      {
        path: "position",
        name: "MainComponentPosition",
        component: MainComponentPosition
      },
      {
        path: "mailing",
        // name: "MainComponentMailing",
        component: MainComponentMailing,
        children: [
          {
            path: "/",
            name: "MainComponentSMS",
            component: MainComponentSMS
          }
        ]
      }
    ]
  },
  {
    path: "/crm/auth",
    component: Auth,
    children: [
      {
        path: "/",
        name: "AuthComponentLogin",
        component: AuthComponentLogin
      }
    ]
  },
  {
    path: "/crm/market",
    component: MainMarket,
    redirect: { name: "ProductPropertiesComponent" },
    children: [
      {
        path: "properties",
        name: "ProductPropertiesComponent",
        component: ProductPropertiesComponent
      },
      {
        path: "menu",
        name: "MenuComponent",
        component: MenuComponent
      },
      {
        path: "shares",
        name: "SharesComponent",
        component: SharesComponent
      },
      {
        path: "products",
        name: "ProductsComponent",
        component: ProductsComponent
      },
      {
        path: "productsGroup",
        name: "ProductsGroupComponent",
        component: ProductsGroupComponent
      }
    ]
  }
];

const router = new VueRouter({
  routes,
  linkActiveClass: "active"
});

export default router;
