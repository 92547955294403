import api from "@/plugins/api";
import {
  marketProductsListUrl,
  marketProductSave,
  marketProductsDelete
} from "@/store/urls";

/* eslint-disable */
function list({ commit }, data) {
    if (data === null) {
        commit("listProducts", []);
        return [];
    }
  return new Promise((resolve, reject) => {
      api
          .post(marketProductsListUrl, { data })
          .then(response => {
              console.log(response.data)
              if (response.status === 200) {
                  commit("listProducts", response.data);
                  resolve(response.data);
              } else {
                  reject(response.data.message);
              }
          })
          .catch(error => {
              reject(error);
          });
  });
}



/* eslint-disable */
function deleteProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
        api
            .get(marketProductsDelete+'?id='+data.id)
            .then(response => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function saveProduct({ commit }, data)
{
    console.log(data);
    let formData = new FormData();
    for (let j in data) {
        if (j == 'selectedFile') {
            for (let i in data[j]) {
                formData.append(i, data[j][i]);
            }
        } else {
            formData.append(j, data[j]);
        }
    }

    return new Promise((resolve, reject) => {
        api
            .post(marketProductSave, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export { list, saveProduct, deleteProduct };
