var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-component",class:{ opacity: _vm.opacity }},[(_vm.windowWidth < 961)?_c('v-navigation-drawer',{attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('MainComponentTopNav')],1):_vm._e(),_c('v-app-bar',{attrs:{"app":"","color":"#0277BD","dark":"","max-width":"100%"}},[(_vm.windowWidth < 961)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),(_vm.windowWidth > 960)?_c('MainComponentTopNav'):_vm._e()],1),_c('v-content',{attrs:{"id":"content"}},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ml-0"},[(
            _vm.windowWidth > 960 &&
              ['MainComponentSettings', 'MainComponentUserSettings'].indexOf(
                _vm.$route.name
              ) === -1
          )?_c('v-col',{staticClass:"scroll-element"},[_c('MainComponentLeftNav')],1):_vm._e(),_c('v-col',{staticClass:"scroll-element",class:{
            maxWidthElement: _vm.$route.matched.some(
              function (ref) {
                          var name = ref.name;

                          return name === 'MainComponentSettings' ||
                name === 'MainComponentUserSettings';
}
            )
          },attrs:{"xl":"9","md":"9","sm":"12","xs":"12","id":"scrollelement"}},[_c('router-view'),(_vm.windowWidth < 961)?_c('MainComponentLeftNav'):_vm._e()],1)],1)],1)],1),_c('v-footer',{attrs:{"color":"#0277BD","app":""}},[(_vm.isMatrona())?_c('span',{staticClass:"white--text"},[_vm._v("© 2021 "),_c('b',[_vm._v("Матрона")])]):_vm._e(),(_vm.isMoroshka())?_c('span',{staticClass:"white--text"},[_vm._v("© 2021 "),_c('b',[_vm._v("Морошка")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }