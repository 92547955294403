function listProducts(state, data) {
  state.products = data.result || [];
}

function setAutocompleteProps(state, data) {
  state.autocompleteProps = data.result || [];
}

function addProp(state, data) {
  data.result.properties.forEach(function(item) {
    let property = {
      propertyName: {
        name: data.result.name,
        alias: data.result.alias,
        id: data.result.id
      },
      name: item.name,
      alias: item.alias,
      id: item.id
    };
    state.props.unshift(property);
  });
}

function addSelectedItem(state, data) {
  state.selectedItem.properties.push(data);
}

function addPropName(state, data) {
  state.selectedItem = data;
}

export {
  listProducts,
  setAutocompleteProps,
  addSelectedItem,
  addPropName,
  addProp
};
