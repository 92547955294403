import api from "@/plugins/api";
import {
  marketMenuSharesCreateUrl,
  marketSharesListUrl,
  marketShareEditUrl,
  marketSharesDelete
} from "@/store/urls";

/* eslint-disable */
function list({ commit }, data) {
  return new Promise((resolve, reject) => {
      api
          .get(marketSharesListUrl, { params: data })
          .then(response => {
              if (response.status === 200) {
                  commit("setProps", response.data);
                  resolve(response);

              } else {
                  reject(response.data.message);
              }
          })
          .catch(error => {
              reject(error);
          });
  });
}

function saveNewShare({ commit }, data)
{
    let formData = new FormData();
    for (let j in data) {
        let val = data[j]
        if (j === 'properties') {
            val = JSON.stringify(data[j])
        }
        formData.append(j, val);
    }

    return new Promise((resolve, reject) => {
        api
            .post(marketMenuSharesCreateUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if (response.status === 200) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}


/* eslint-disable */
function deleteShare({ commit }, data) {
    return new Promise((resolve, reject) => {
        api
            .get(marketSharesDelete+'?id='+data.id)
            .then(response => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function editShare({ commit }, data)
{
    let formData = new FormData();
    for (let j in data) {
        let val = data[j]
        if (j === 'properties') {
            val = JSON.stringify(data[j])
        }
        formData.append(j, val);
    }
    return new Promise((resolve, reject) => {
        api
            .post(marketShareEditUrl, formData )
            .then(response => {
                if (response.status === 200) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export { list, editShare, saveNewShare, deleteShare };
