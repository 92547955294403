function setProps(state, data) {
  state.props = data.result || [];
}

function setAutocompleteProps(state, data) {
  state.autocompleteProps = data.result || [];
}

function addProp(state, data) {
  data.result.properties.forEach(function(item){
    let property = {
      propertyName: {
        name: data.result.name,
        alias: data.result.alias,
        id: data.result.id,
      },
      name: item.name,
      alias: item.alias,
      id: item.id,
    }
    state.props.unshift(property);
  })
}

function setSelectedItem(state, data) {
  state.selectedItem = data.result || {};
}

function addSelectedItem(state, data)
{
  state.selectedItem.properties.push(data)
}

function addPropName(state, data) {
    state.selectedItem = data
}

export { setProps, setAutocompleteProps, setSelectedItem, addSelectedItem, addPropName, addProp };
