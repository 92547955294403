import api from "@/plugins/api";
import {
    marketPropsListUrl,
    marketPropsAutocompleteUrl,
    marketPropertyItemUrl,
    marketCreatePropertyUrl
} from "@/store/urls";

/* eslint-disable */
function list({ commit }, data) {
  return new Promise((resolve, reject) => {
      api
          .get(marketPropsListUrl, { params: data })
          .then(response => {
              if (response.status === 200) {
                  commit("setProps", response.data);
                  resolve(response);
              } else {
                  reject(response.data.message);
              }
          })
          .catch(error => {
              reject(error);
          });
  });
}

function autocompleteList({ commit }, data) {
  return new Promise((resolve, reject) => {
      api
          .get(marketPropsAutocompleteUrl, { params: data })
          .then(response => {
              if (response.status === 200) {
                  commit("setAutocompleteProps", response.data);
                  resolve(response);
              } else {
                  reject(response.data.message);
              }
          })
          .catch(error => {
              reject(error);
          });
  });
}

function loadItem({ commit }, data) {
    return new Promise((resolve, reject) => {
        api
            .get(marketPropertyItemUrl+data.id)
            .then(response => {
                if (response.status === 200) {
                    commit("setSelectedItem", response.data);
                    resolve(response);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function newProperty({ commit }, data)
{
    commit("addSelectedItem", {id: null, alias: null, name: null});
}

function newPropName({ commit }, data)
{
    commit("addPropName", {id: null, alias: null, name: null, properties: []});
}

function createProp({ commit }, data)
{
    return new Promise((resolve, reject) => {
        api
            .post(marketCreatePropertyUrl, data)
            .then(response => {
                if (response.status === 200) {
                    commit("setSelectedItem", response.data);
                    commit("addProp", response.data);
                    resolve(response);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function saveProp({ commit }, data)
{
    return new Promise((resolve, reject) => {
        api
            .put(marketCreatePropertyUrl+'/'+data.id, data)
            .then(response => {
                if (response.status === 200) {
                    commit("setSelectedItem", response.data);
                    resolve(response);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function deleteProp({ commit }, data)
{
    return new Promise((resolve, reject) => {
        api
            .delete(marketPropertyItemUrl+data.id)
            .then(response => {
                if (response.status === 200) {
                    // commit("setSelectedItem", response.data);
                    resolve(response);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export { list, autocompleteList, loadItem, newProperty, newPropName, createProp, saveProp, deleteProp };
