<template>
  <v-app-bar app>
    <v-slide-group multiple show-arrows>
      <v-slide-item
        v-for="(item, n) in listFiltered"
        :key="n"
        v-slot="{ active, toggle }"
      >
        <v-btn
          class="mx-2"
          :input-value="active"
          depressed
          @click="toggle"
          :to="{ name: item.routeName }"
        >
          {{ item.title }}
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </v-app-bar>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";
import { mapState } from "vuex";
Vue.use(VueCookies);
export default {
  name: "MainComponentTopNav",
  data() {
    return {
      list: [
        {
          title: "Свойства",
          routeName: "ProductPropertiesComponent"
        },
        {
          title: "Меню",
          routeName: "MenuComponent"
        },
        {
          title: "Cкидки",
          routeName: "MainComponentNews"
        },
        {
          title: "Акции",
          routeName: "SharesComponent"
        },
        {
          title: "Товары",
          routeName: "ProductsComponent"
        },
        {
          title: "Группы товаров",
          routeName: "ProductsGroupComponent"
        }
      ]
    };
  },
  methods: {
    exit() {
      VueCookies.remove("token");
      this.$router.push("/crm/auth");
    },
    myScroll() {
      setTimeout(() => {
        document.getElementById("scrollelement").scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }, 500);
    }
  },
  computed: {
    ...mapState({
      userRole: state => state.user.user.role
    }),
    listFiltered() {
      /* eslint-disable */
          let filteredList = []
          this.list.forEach(item => {
              if (item.roles === undefined || (item.roles !== undefined && item.roles.indexOf(this.userRole) > -1)) {
                  filteredList.push(item)
              }
          })
          return filteredList
      }
  }
};
</script>
