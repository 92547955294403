let cidMixin = {

    methods: {
        isMoroshka() {
            return this.sId === 'moroshka';
        },
        isMatrona() {
            return this.sId === 'matrona';
        }
    },
    computed: {
        sId: function () {
            return window.cId;
        },
        apiUrl: function () {
            return window.matronaApiURL;
        }
    }

}

export {
    cidMixin
}