<template>
  <v-row class="mb-6">
    <v-col class="col-sm-6">
      <v-card>
        <v-card-title>
          СПИСОК СВОЙСТВ
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn color="green white&#45;&#45;text" style="margin-right: 0 !important;" @click="newPropertyNameRow()">+ Новое</v-btn>-->
        </v-card-title>
        <hr />
        <v-card-text
          ><v-text-field
            placeholder="Имя / Значения свойств или их псевдонимы"
            append-icon="mdi-magnify"
            @change="getProperties"
            v-model="search"
          ></v-text-field>
        </v-card-text>
        <v-data-table
          :headers="headers"
          :single-select="true"
          item-key="id"
          :items="marketProps"
          :loading="loading"
          v-model="selectedRows"
          loading-text="Загрузка..."
        >
          <template v-slot:item="{ item }">
            <tr :key="item.id" :class="selectedRows.indexOf(item.id) > -1 ? 'blue--lighten-5' : ''" @click="propClicked(item)">
              <td>{{item.propertyName.name}}</td>
              <td>{{item.propertyName.alias}}</td>
              <td>{{item.name}}</td>
              <td>{{item.alias}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col class="col-sm-6">
      <v-card min-width="300" tile v-bind:class="{
        'height-screen': true,
        'green--lighten-5': selectedItem.id !== undefined && selectedItem.id === null,
        'blue--lighten-5': selectedItem.id !== undefined && selectedItem.id !== null
      }">
        <v-card-title>СВОЙСТВО</v-card-title>
        <hr />
        <v-card-subtitle class="font-weight-bold">Имя</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col class="col-sm-6">
              <v-text-field
                :disabled="selectedItem.id !== null"
                label="Имя"
                :loading="loadingItem"
                v-model="selectedItem.name"
              ></v-text-field
            ></v-col>
            <v-col class="col-sm-6"
              ><v-text-field label="Псевдоним" :loading="loadingItem" v-model="selectedItem.alias"></v-text-field
            ></v-col>
          </v-row>
        </v-card-text>
        <hr />
        <v-row>
              <v-col class="col-sm-9">
                <v-card-subtitle class="font-weight-bold">Значения</v-card-subtitle>
              </v-col>
              <v-col class="col-sm-3">
                <v-card-subtitle class="font-weight-bold text-right text-no-wrap">Фильтр</v-card-subtitle>
              </v-col>
        </v-row>

        <v-card-text>
          <v-row
              :key="itemProp.id"
              v-for="(itemProp) in selectedItem.properties"
              v-show="itemProp.isDeleted === false || itemProp.isDeleted === undefined"
          >
            <v-col  class="col-sm-6 pb-0 pt-0">
<!--              <v-btn class="pr-0 pl-0" fab x-small color="orange white&#45;&#45;text">-->
<!--                <v-icon>mdi-delete</v-icon>-->
<!--              </v-btn>-->
              <v-text-field
                :disabled="itemProp.id !== null"
                label="Имя"
                v-model="itemProp.name"
              ></v-text-field
            ></v-col>
            <v-col class="col-sm-5 pb-0 pt-0">

<!--              <v-text-field-->
<!--                label="Псевдоним"-->
<!--                v-model="itemProp.alias"-->
<!--                append-icon="mdi-delete"-->
<!--                @click:append="deleteProp(itemProp)"-->
<!--              ></v-text-field>-->

              <v-text-field
                label="Псевдоним"
                v-model="itemProp.alias"
              ></v-text-field>

            </v-col>
            <v-col class="col-sm-1 pb-0 pt-0">

              <v-switch
                  v-model="itemProp.inFilter"
              ></v-switch>

            </v-col>

          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
<!--          <v-btn color="blue white&#45;&#45;text" @click="addPropertiesRow(selectedItem)" :disabled="selectedItem.id === undefined">+ Добавить значение</v-btn>-->
          <v-btn color="green white--text">
            <span v-if="selectedItem.id === null" @click="createProp()">Создать</span>
            <span v-else @click="saveProp()">Сохранить</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProductPropertiesComponent",
  data() {
    return {
      headers: [
        {
          text: "Имя",
          // align: 'start',
          // sortable: false,
          value: "propertyName.name"
        },
        { text: "Имя (Псевдоним)", value: "propertyName.alias" },
        { text: "Значение", value: 'name' },
        { text: "Значение (Псевдоним)", value: 'alias' }
      ],

      loading: false,
      loadingItem: false,
      search: null,
      selectedRows: []
    };
  },
  methods: {
    getProperties() {
      this.loading = true;

      this.$store
        .dispatch("marketProps/list", {search: this.search})
        // .then(response => {
        // })
        .finally(() => {
          this.loading = false;
        });
    },
    getItemProp(id) {
      this.loadingItem = true;

      this.$store
        .dispatch("marketProps/loadItem", {id: id})
        // .then(response => {
        // })
        .finally(() => {
          this.loadingItem = false;
        });
    },
    propClicked(item) {
      this.toggleSelection(item.id);
      this.getItemProp(item.propertyName.id)
    },
    toggleSelection(keyID) {
      this.selectedRows = []
      this.selectedRows.push(keyID)
    },
    addPropertiesRow(item) {
      // eslint-disable-next-line no-console
      console.log(item)
      this.$store.dispatch("marketProps/newProperty", {});
    },
    newPropertyNameRow() {
      this.$store.dispatch("marketProps/newPropName", {});
    },
    createProp() {
      this.$store.dispatch("marketProps/createProp", this.selectedItem);
    },
    saveProp() {
      this.$store.dispatch("marketProps/saveProp", this.selectedItem);
    },
    deleteProp(item) {
      if (confirm("Удалить значение \""+item.name+"\"?")) {
        item.isDeleted = true
      }
    }
  },
  computed: {
    ...mapState({
      marketProps: state => state.marketProps.props,
      selectedItem: state => state.marketProps.selectedItem,
      snackBar: state => state.snackBar
    })
  },
  mounted() {
    this.getProperties();
  }
};
</script>
<style>
.blue--lighten-5 {
  background-color: #E3F2FD !important;
}
.green--lighten-5 {
  background-color: #E8F5E9 !important;
}
.height-screen {
  height: 100vh;
  overflow: scroll;
}
</style>
