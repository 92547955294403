<template>
  <v-row class="mb-6">
    <v-col class="col-sm-6">
      <v-card>
        <v-row style="margin: 0">
          <v-col class="col-sm-12">
            <v-card-title>ТОВАРЫ</v-card-title>
          </v-col>
        </v-row>
        <hr />
        <filter-properties-component></filter-properties-component>
        <v-card-text> </v-card-text>
        <v-data-table
          style="cursor: pointer;"
          :headers="headers"
          :single-select="true"
          item-key="id"
          :items="productsList"
          :loading="loading"
          v-model="selectedRows"
          loading-text="Загрузка..."
          :search="search"
        >
          <template v-slot:item="{ item }">
            <tr
              :key="item.id"
              :class="
                selectedRows.indexOf(item.id) > -1 ? 'blue--lighten-5' : ''
              "
              @click="productClicked(item)"
              style="margin-top: 50px!important;"
            >
              <td style="width: 25%!important;margin-top: 50px;">
                <v-card
                  v-if="item.files.hasOwnProperty(0)"
                  style="display: inline-block; margin-left: 20px;"
                >
                  <v-img
                    max-height="200"
                    max-width="200"
                    :src="item.files[0].path"
                  >
                  </v-img>
                </v-card>
              </td>
              <td style="width: 70%!important;">
                <p>{{ item.name }}</p>
                <p>{{ item.description }}</p>
                <!--                <h3>В наличии {{ item.balance }}</h3>-->
              </td>
              <td style="width: 5%!important;">
                {{ item.price }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col class="col-sm-6">
      <v-card
        min-width="300"
        tile
        v-bind:class="{
          'height-screen': true,
          'blue--lighten-5': selectedItem !== undefined && selectedItem !== null
        }"
      >
        <v-row style="margin: 0;">
          <v-col class="col-sm-12">
            <v-card-title>РЕДАКТОР</v-card-title>
          </v-col>
        </v-row>
        <hr />
        <!--        <v-progress-circular-->
        <!--          v-show="loadingEffectAnimation.preloader"-->
        <!--          :size="50"-->
        <!--          color="primary"-->
        <!--          indeterminate-->
        <!--          class="preloader"-->
        <!--        ></v-progress-circular>-->

        <v-col
          cols="12"
          sm="12"
          md="12"
          :class="{ blockOpacety: loadingEffectAnimation.preloader }"
          v-show="blockImages.show"
        >
          <template v-if="blockImages.images.show == true">
            <v-row>
              <v-col
                v-for="(item, index) in productsItem.files"
                :key="index"
                style="text-align: center;"
                class="col-sm-3"
              >
                <v-card style="display: inline-block; margin-left: 20px;">
                  <v-img
                    :key="index"
                    max-height="200"
                    max-width="200"
                    :src="item.path"
                  >
                    <v-icon
                      @click="deleteImage(item, index)"
                      style="float: right;color: red;cursor: pointer;"
                    >
                      mdi-delete
                    </v-icon></v-img
                  >
                </v-card>
              </v-col>
            </v-row>
          </template>
          <v-file-input
            :error-messages="blockImages.rulesLoadImages.textError"
            :error="blockImages.rulesLoadImages.valueError"
            :success="blockImages.rulesLoadImages.valueSuccess"
            @change="onFileChanged"
            v-model="productsItem.selectedFile"
            label="Изображение"
            multiple
            filled
            counter
            prepend-icon="mdi-camera"
            show-size
            accept=".png, .jpg, .jpeg"
            style="margin-top: 30px;"
          >
          </v-file-input>

          <!--            </v-col>-->
          <!--          </v-row>-->
          <v-divider color="#333"></v-divider>
          <v-text-field
            disabled
            rows="1"
            auto-grow
            v-model="productsItem.name"
            label="Наименование"
            placeholder="Введите наименование товара"
          ></v-text-field>

          <v-textarea
            v-model="productsItem.description"
            label="Описание"
            placeholder="Введите описание товара"
            filled
            auto-grow
            rows="4"
            row-height="30"
          ></v-textarea>

          <v-text-field
            disabled
            rows="1"
            auto-grow
            v-model="productsItem.price"
            label="Цена"
            placeholder="Укажите цену"
          ></v-text-field>

          <v-text-field
            disabled
            rows="1"
            auto-grow
            v-model="productsItem.balance"
            label="В наличии"
            placeholder="Количество товаров"
          ></v-text-field>
          <v-divider color="#333"></v-divider>
          <v-checkbox
            v-model="productsItem.is_active"
            class="mx-2"
            label="Активность"
          ></v-checkbox>
          <v-divider color="#333"></v-divider>
          <v-row style="float: left;margin-top: 25px;">
            <v-col class="col-sm-6">
              <v-btn color="red white--text">
                <span @click="deleteProduct(true)">Удалить</span>
              </v-btn>
            </v-col>

            <v-dialog
              v-model="blockProduct.deleteShowModal"
              persistent
              max-width="600px"
            >
              <v-card>
                <v-card-title style="display: block;text-align: center;">
                  <span class="headline">Удалить продукт?</span>
                </v-card-title>
                <v-card-actions style="display: block;text-align: center;">
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" @click="deleteProduct(false)"
                    >Да</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    @click="blockProduct.deleteShowModal = false"
                    >Нет
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-col class="col-sm-6">
              <v-btn color="green white--text">
                <span @click="saveProduct()">Сохранить</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import FilterPropertiesComponent from "./FilterPropertiesComponent";
import { mapState } from "vuex";

export default {
  name: "ProductsComponent",
  components: { FilterPropertiesComponent },
  data() {
    return {
      blockProduct: {
        deleteShowModal: false,
        change: false,
        oldProductItem: []
      },
      blockImages: {
        show: false,
        selectedFile: [],
        images: {
          array: [],
          show: false
        },
        rulesLoadImages: {
          valueSuccess: false,
          valueError: false,
          textError: ""
        }
      },
      mode: "",
      modal: false,
      isSelecting: false,
      headers: [
        { text: "Картинка", value: "name" },
        { text: "Название", value: "alias" },
        { text: "Цена", value: "date[0]", filterable: false }
      ],
      selectedItem: null,
      loading: false,
      loadingItem: false,
      search: null,
      selectedRows: [],
      productsItem: {
        name: "",
        description: "",
        price: 0,
        balance: 0,
        is_active: false,
        selectedFile: [],
        files: [],
        filesDelete: []
      },
      showNullImage: false,
      newCreateShare: false,
      error: {
        name: false
      },
      actiiveText: {
        true: "Активна",
        false: "Отключена"
      },
      oldImage: "",
      loadingEffectAnimation: {
        preloader: false,
        alert: false,
        responseAlert: "error",
        textAlert: {
          success: "Данный успешно сохранены",
          error:
            "Ошибка! Не удалось сохранить. Проверьте правильность ввода данных"
        }
      }
    };
  },
  methods: {
    deleteProduct(stepDelete) {
      this.blockProduct.deleteShowModal = stepDelete;

      if (stepDelete == false) {
        this.$store
          .dispatch("marketProducts/deleteProduct", this.productsItem)
          .finally(() => {
            this.getListProducts();
          });
      }
    },
    saveProduct() {
      this.$store
        .dispatch("marketProducts/saveProduct", this.productsItem)
        .finally(() => {});
    },
    deleteImage(item, index) {
      this.productsItem.filesDelete.push(item.path);
      this.productsItem.files.splice(index, 1);

      if (this.productsItem.selectedFile.hasOwnProperty(item.selectedFile)) {
        this.productsItem.selectedFile.splice(item.selectedFile, 1);
      }
    },
    onFileChanged() {
      this.blockImages.rulesLoadImages.valueError = false;
      this.blockImages.rulesLoadImages.valueSuccess = false;
      this.blockImages.rulesLoadImages.textError = "";

      if (this.productsItem.selectedFile.length > 0) {
        for (let i in this.productsItem.selectedFile) {
          if (
            this.productsItem.selectedFile[i].type !== "image/png" &&
            this.productsItem.selectedFile[i].type !== "image/jpeg"
          ) {
            this.blockImages.rulesLoadImages.valueError = true;
            this.blockImages.rulesLoadImages.textError =
              "Ошибка! Изображения должны быть расширениями jpeg, png, jpg";
            return;
          }

          if (this.productsItem.selectedFile[i].size > 500000) {
            this.blockImages.rulesLoadImages.valueError = true;
            this.blockImages.rulesLoadImages.textError =
              "Ошибка! Размер загружаемого изображения должен быть меньше 500 кб";
            return;
          }

          let _this = this;
          let reader = new FileReader();
          if (this.productsItem.selectedFile[i] !== null) {
            reader.onload = function(reader) {
              _this.productsItem.files.push({
                path: reader.target.result,
                selectedFile: i
              });
            };
          }
          reader.readAsDataURL(this.productsItem.selectedFile[i]);
        }

        setTimeout(() => {
          this.blockImages.images.show = true;
        }, 1000);
        return;
      }
    },
    getListProducts(filterParameters = {}) {
      this.loading = true;
      this.productsItem = [];
      this.blockImages.show = false;
      this.$store
        .dispatch("marketProducts/list", filterParameters)
        .finally(() => {
          this.loading = false;
        });
    },
    productClicked(item) {
      this.productsItem = item;
      this.blockImages.show = true;
      this.blockImages.images.show = true;
      this.selectedItem = item.id;
      this.toggleSelection(item.id);
    },
    toggleSelection(keyID) {
      this.selectedRows = [];
      this.selectedRows.push(keyID);
    }
  },

  computed: {
    ...mapState({
      productsList: state => state.marketProducts.products,
      propertyNameAutocompleteItems: state => {
        return state.marketMenu.menuAutocomplete;
      }
    })
  },
  mounted() {
    let _this = this;
    this.$root.$on("filterPropertiesList", function(filter = []) {
      _this.getListProducts({ search: filter });
    });

    // this.$on("filterPropertiesList", (filter = {}) => {
    //   this.getListProducts(filter);
    // });

    this.getListProducts();
    // this.getListPropertyName();
  }
};
</script>

<style scoped>
.preloader {
  top: 25%;
  left: 50%;
  position: absolute;
  z-index: 100;
}
.blockOpacety {
  opacity: 0.5;
}
.self-style {
  height: 100%;
}
.self-style >>> .v-icon {
  font-size: 150px;
  height: 100%;
  width: 100%;
}
.self-style >>> .v-input__prepend-outer {
  width: 100%;
  height: 100%;
}

.self-style >>> .v-input__icon {
  height: 100%;
}
</style>

<style>
.blue--lighten-5 {
  background-color: #e3f2fd !important;
}
.green--lighten-5 {
  background-color: #e8f5e9 !important;
}
.height-screen {
  height: 100vh;
  overflow: scroll;
}
</style>
