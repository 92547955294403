<template>
  <v-col class="col-sm-12">
    <v-row
      :style="{ marginTop: mainStyle[index].value }"
      v-for="(item, index) in blockSelectFilter.selected"
      :key="index"
    >
      <v-col class="col-sm-5">
        <v-card-text>
          <v-autocomplete
            :error="blockSelectFilter.errorPropertyName[index].value"
            :error-messages="
              blockSelectFilter.errorPropertyName[index].messages
            "
            @change="filterProperty(index)"
            :key="index"
            :loading="loadingItem"
            chips
            deletable-chips
            :items="blockSelectFilter.propertyName"
            v-model="blockSelectFilter.selected[index].selectedPropertyName"
            item-text="name"
            item-value="id"
            hide-no-data
            hide-selectedpg_catalog
            label="Свойство"
            return-object
          ></v-autocomplete>
        </v-card-text>
      </v-col>
      <v-col class="col-sm-5">
        <v-card-text>
          <v-autocomplete
            :error="blockSelectFilter.errorProperty.value"
            :error-messages="blockSelectFilter.errorProperty.messages"
            :key="index"
            :loading="loadingItem"
            chips
            deletable-chips
            :items="blockSelectFilter.property[index]"
            v-model="blockSelectFilter.selected[index].selectedProperty"
            item-text="name"
            item-value="id"
            hide-no-data
            hide-selectedpg_catalog
            label="Значение"
            return-object
          ></v-autocomplete>
        </v-card-text>
      </v-col>
      <v-col class="col-sm-1">
        <v-card-text>
          <v-icon
            @click="deleteFilter(item, index)"
            style="float: right;color: red;cursor: pointer;padding-top: 27px;font-size: 30px;"
          >
            mdi-delete
          </v-icon></v-card-text
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col class="col-sm-12">
        <v-btn style="margin-right: 20px;" color="grey white--text">
          <span @click="addProperty()">Добавить свойство</span>
        </v-btn>
        <v-btn color="green white--text">
          <span @click="getValueFilter()">Применить</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "FilterPropertiesComponent",
  data() {
    return {
      mainStyle: [{ value: "" }],
      blockSelectFilter: {
        errorPropertyName: [],
        errorProperty: [],
        propertyName: [],
        property: [],
        selected: [],
        firstFilter: 0
      },
      loadingItem: false,
      loading: false
    };
  },
  methods: {
    deleteFilter(item, index) {
      this.blockSelectFilter.property.splice(index, 1);
      this.blockSelectFilter.selected.splice(index, 1);
    },
    filterProperty(index) {
      this.validatesRules(index, false);

      if (
        this.blockSelectFilter.selected[index].selectedPropertyName !== null
      ) {
        this.loadingItem = true;

        this.$store
          .dispatch("marketProps/loadItem", {
            id: this.blockSelectFilter.selected[index].selectedPropertyName.id
          })
          .then(response => {
            this.blockSelectFilter.property[index] =
              response.data.result.properties;
          })
          .finally(() => {
            this.loadingItem = false;
          });
      } else {
        this.blockSelectFilter.property[index] = [];
        this.blockSelectFilter.selected[index].selectedProperty = null;
      }
    },
    addProperty() {
      if (this.validatesRules("", true) == false) {
        this.blockSelectFilter.selected.push({
          selectedPropertyName: null,
          selectedProperty: null
        });

        if (this.blockSelectFilter.selected.length > 1) {
          this.mainStyle.push({
            value: "-50px"
          });
        }

        this.blockSelectFilter.errorPropertyName.push({
          value: false,
          messages: ""
        });
      }
      this.blockSelectFilter.firstFilter = 1;
    },
    validatesRules(index = "", push = false) {
      if (this.blockSelectFilter.firstFilter === 1 && push == true) {
        let additionalFlag = false;
        for (let i in this.blockSelectFilter.selected) {
          if (this.blockSelectFilter.selected[i].selectedPropertyName == null) {
            additionalFlag = true;
            this.blockSelectFilter.errorPropertyName[i].value = true;
            this.blockSelectFilter.errorPropertyName[i].messages =
              "Выберите свойство, прежде чем создать новый фильтр";
          } else {
            this.blockSelectFilter.errorPropertyName[i].value = false;
            this.blockSelectFilter.errorPropertyName[i].messages = "";
          }
        }
        if (
          additionalFlag == true &&
          this.blockSelectFilter.firstFilter === 1
        ) {
          return true;
        } else {
          return false;
        }
      } else if (push == false && this.blockSelectFilter.firstFilter === 1) {
        if (
          this.blockSelectFilter.selected[index].selectedPropertyName !== null
        ) {
          this.blockSelectFilter.errorPropertyName[index].value = false;
          this.blockSelectFilter.errorPropertyName[index].messages = "";

          return false;
        }
      }

      return false;
    },
    getValueFilter() {
      if (this.blockSelectFilter.selected.length == 0) {
        alert("Не выбрано не одного свойства для поиска");
        return;
      }
      this.$root.$emit("filterPropertiesList", this.blockSelectFilter.selected);
    },
    getListPropertyName() {
      this.loading = true;

      this.$store
        .dispatch("marketProps/autocompleteList", { search: this.search })
        .then(response => {
          this.blockSelectFilter.propertyName = response.data.result;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.getListPropertyName();
  }
};
</script>

<style scoped></style>
