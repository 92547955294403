import api from "@/plugins/api";
import {
  marketProductGroupList,
  marketProductGroupSave,
  marketProductsGroupDelete
} from "@/store/urls";

/* eslint-disable */
function list({ commit }, data) {
  return new Promise((resolve, reject) => {
      api
          .get(marketProductGroupList)
          .then(response => {
              if (response.status === 200) {

                  console.log(response.data);
                  commit("listProductsGroup", response.data);
                  resolve(response);

              } else {
                  reject(response.data.message);
              }
          })
          .catch(error => {
              reject(error);
          });
  });
}

/* eslint-disable */
function deleteProductsInGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
        api
            .delete(marketProductsGroupDelete+data.id)
            .then(response => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function saveGroupProduct({ commit }, data)
{
    return new Promise((resolve, reject) => {
        api
            .post(marketProductGroupSave, data)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(response.data.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export { list, saveGroupProduct, deleteProductsInGroup };
