<template>
  <div>
    <TopNavigationComponent />

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import TopNavigationComponent from "./components/TopNavigationComponent";

export default {
  name: "MarketComponent",
  components: {
    TopNavigationComponent
  },
  data() {
    return {};
  },
  mounted() {}
};
</script>
